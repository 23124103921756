.SP {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.SP .div {
  background-color: #ffffff;
  height: 8724px;
  overflow: hidden;
  position: relative;
  width: 375px;
}

.SP .overlap {
  height: 1988px;
  left: 0;
  position: absolute;
  top: 1251px;
  width: 375px;
}

.SP .element {
  height: 608px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 375px;
}

.SP .view {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 1446px;
  left: 0;
  position: absolute;
  top: 542px;
  width: 375px;
}

.SP .title {
  background-image: url(../../../static/img/rectangle-5.svg);
  background-size: 100% 100%;
  height: 175.28px;
  position: relative;
  width: 375px;
}

.SP .group {
  height: 116px;
  left: 48px;
  position: relative;
  top: 30px;
  width: 278px;
}

.SP .overlap-group {
  height: 116px;
  position: relative;
}

.SP .img {
  height: 56px;
  left: 0;
  position: absolute;
  top: 24px;
  width: 278px;
}

.SP .overlap-wrapper {
  height: 116px;
  left: 39px;
  position: absolute;
  top: 0;
  width: 195px;
}

.SP .overlap-2 {
  height: 116px;
  position: relative;
  width: 193px;
}

.SP .frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 115px;
  left: 0;
  position: absolute;
  top: 0;
  width: 193px;
}

.SP .frame-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 94px;
  position: relative;
  width: 142px;
}

.SP .text-wrapper {
  color: var(--glj7-6w);
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 1.76px;
  line-height: 33px;
  margin-top: -1.13px;
  position: relative;
  text-align: center;
  width: 142px;
}

.SP .overlap-group-wrapper {
  height: 72px;
  margin-right: -2px;
  margin-top: -11.28px;
  position: relative;
  width: 144px;
}

.SP .overlap-group-2 {
  height: 37px;
  left: -4px;
  position: relative;
  top: 21px;
  width: 150px;
}

.SP .rectangle {
  background-color: #f3ffad;
  height: 14px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 150px;
}

.SP .image {
  height: 32px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 130px;
}

.SP .div-wrapper {
  height: 33px;
  margin-top: -12.41px;
  position: relative;
  width: 138px;
}

.SP .p {
  color: var(--glj7-6w);
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 22px;
  font-weight: 900;
  left: 0;
  line-height: 33px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.SP .span {
  letter-spacing: 0.39px;
}

.SP .text-wrapper-2 {
  letter-spacing: 0.24px;
}

.SP .text-wrapper-3 {
  letter-spacing: -0.19px;
}

.SP .text-wrapper-4 {
  letter-spacing: 0;
}

.SP .text-wrapper-5 {
  letter-spacing: -0.34px;
}

.SP .text-wrapper-6 {
  color: var(--glj7-6w);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 159px;
  letter-spacing: 1.76px;
  line-height: 33px;
  position: absolute;
  text-align: center;
  top: 82px;
  transform: rotate(8.6deg);
  white-space: nowrap;
  width: 25px;
}

.SP .frame-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: var(--1-48) 8px var(--1-40) 8px;
  position: relative;
  width: 100%;
}

.SP .frame-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--1-40);
  position: relative;
  width: 281px;
}

.SP .title-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 208px;
}

.SP .titel {
  align-items: baseline;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.SP .text-wrapper-7 {
  color: var(--MA-ah-6n);
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 60px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.SP .text-wrapper-8 {
  color: var(--MA-ah-6n);
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 1.6px;
  line-height: 30px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.SP .rectangle-2 {
  background-color: #f3ffad;
  height: 8px;
  position: relative;
  width: 80px;
}

.SP .frame-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-40);
  position: relative;
  width: 100%;
}

.SP .element-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-24);
  position: relative;
  width: 100%;
}

.SP .frame-5 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: var(--1-16);
  position: relative;
  width: 100%;
}

.SP .group-2 {
  height: 88.5px;
  object-fit: cover;
  position: relative;
  width: 90px;
}

.SP .mask-group {
  height: 188px;
  margin-bottom: -8px;
  margin-right: -13px;
  position: relative;
  width: 188px;
}

.SP .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.SP .group-3 {
  height: 35px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 280px;
}

.SP .rectangle-3 {
  background-color: #f3ffad;
  height: 8px;
  left: 0;
  position: absolute;
  top: 0;
  width: 280px;
}

.SP .rectangle-4 {
  background-color: #f3ffad;
  height: 8px;
  left: 0;
  position: absolute;
  top: 27px;
  width: 115px;
}

.SP .text-wrapper-9 {
  align-self: stretch;
  color: var(--MA-ah-6n);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.72px;
  line-height: 27px;
  margin-top: -1px;
  position: relative;
  text-align: justify;
}

.SP .text-wrapper-10 {
  align-self: stretch;
  color: var(--mfa-1f9);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.56px;
  line-height: 21px;
  position: relative;
  text-align: justify;
}

.SP .element-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-24);
  justify-content: center;
  position: relative;
  width: 100%;
}

.SP .mask-group-2 {
  height: 187.72px;
  margin-bottom: -7.72px;
  margin-right: -13px;
  position: relative;
  width: 188px;
}

.SP .frame-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-8);
  position: relative;
  width: 100%;
}

.SP .group-4 {
  height: 35px;
  left: 0;
  position: absolute;
  top: 17px;
  width: 280px;
}

.SP .rectangle-5 {
  background-color: #f3ffad;
  height: 8px;
  left: 0;
  position: absolute;
  top: 27px;
  width: 263px;
}

.SP .text-wrapper-11 {
  align-self: stretch;
  color: var(--MA-ah-6n);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.72px;
  line-height: 27px;
  margin-top: -1px;
  position: relative;
}

.SP .group-5 {
  height: 35px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 280px;
}

.SP .rectangle-6 {
  background-color: #f3ffad;
  height: 8px;
  left: 0;
  position: absolute;
  top: 27px;
  width: 71px;
}

.SP .view-2 {
  height: 254px;
  left: 0;
  position: absolute;
  top: 3239px;
  width: 375px;
}

.SP .frame-8 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  left: 20px;
  position: relative;
  width: 335px;
}

.SP .title-3 {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  left: 45px;
  position: absolute;
  top: 20px;
  z-index: 2;
}

.SP .group-6 {
  height: 30px;
  position: relative;
  width: 16px;
}

.SP .text-wrapper-12 {
  color: var(--u-xe-hpg);
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 27px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 40.5px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.SP .img-2 {
  height: 103px;
  margin-top: -17px;
  position: relative;
  width: 76px;
  z-index: 1;
}

.SP .text {
  align-items: center;
  align-self: stretch;
  background-color: #ceedff80;
  border-radius: 20px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-top: -17px;
  padding: var(--1-24);
  position: relative;
  width: 100%;
  z-index: 0;
}

.SP .div-2 {
  color: transparent;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: -3.5px;
  margin-right: -3.5px;
  margin-top: -1px;
  position: relative;
  width: 294px;
}

.SP .text-wrapper-13 {
  color: #1c1c1c;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-decoration: underline;
}

.SP .text-wrapper-14 {
  color: #4d4d4d;
  letter-spacing: 0.05px;
}

.SP .text-wrapper-15 {
  color: #4d4d4d;
  letter-spacing: -0.03px;
}

.SP .text-wrapper-16 {
  color: #4d4d4d;
  letter-spacing: -0.82px;
}

.SP .text-wrapper-17 {
  color: #4d4d4d;
  letter-spacing: 0.13px;
}

.SP .text-wrapper-18 {
  color: #009bf2;
  font-weight: 900;
  letter-spacing: 0.28px;
}

.SP .text-wrapper-19 {
  color: #009bf2;
  font-weight: 900;
  letter-spacing: 0.2px;
}

.SP .text-wrapper-20 {
  color: #009bf2;
  font-weight: 900;
  letter-spacing: 0.05px;
}

.SP .text-wrapper-21 {
  color: #009bf2;
  font-weight: 900;
  letter-spacing: 0.1px;
}

.SP .text-wrapper-22 {
  color: #009bf2;
  font-weight: 900;
  letter-spacing: -0.33px;
}

.SP .view-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--1-24);
  left: 0;
  padding: 56px 0px;
  position: absolute;
  top: 3493px;
  width: 375px;
}

.SP .frame-9 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-24);
  position: relative;
  width: 100%;
}

.SP .frame-10 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.SP .title-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 194px;
  position: relative;
  width: 100%;
}

.SP .img-3 {
  height: 30.78px;
  position: relative;
  width: 39.23px;
}

.SP .frame-11 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 147px;
  position: relative;
  width: 100%;
}

.SP .rectangle-7 {
  background-color: #f3ffad;
  height: 8px;
  left: 20px;
  position: absolute;
  top: 58px;
  width: 335px;
}

.SP .div-3 {
  align-self: stretch;
  color: var(--MA-ah-6n);
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 24px;
  font-weight: 900;
  line-height: 36px;
  margin-top: -1.2px;
  position: relative;
  text-align: center;
}

.SP .text-wrapper-23 {
  letter-spacing: 0.23px;
}

.SP .text-wrapper-24 {
  letter-spacing: 0.12px;
}

.SP .text-wrapper-25 {
  letter-spacing: -0.29px;
}

.SP .text-wrapper-26 {
  letter-spacing: -0.81px;
}

.SP .text-wrapper-27 {
  letter-spacing: -0.52px;
}

.SP .text-wrapper-28 {
  letter-spacing: -0.35px;
}

.SP .text-wrapper-29 {
  letter-spacing: -0.06px;
}

.SP .logo {
  height: 59px;
  position: relative;
  width: 153px;
}

.SP .LGBTQ {
  color: transparent;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  width: 279px;
}

.SP .text-wrapper-30 {
  color: #4d4d4d;
  letter-spacing: 0.1px;
}

.SP .text-wrapper-31 {
  color: #4d4d4d;
  letter-spacing: -0.87px;
}

.SP .text-wrapper-32 {
  color: #009bf2;
  font-weight: 700;
  letter-spacing: 0.23px;
}

.SP .text-wrapper-33 {
  color: #009bf2;
  font-weight: 700;
  letter-spacing: 0.13px;
}

.SP .text-wrapper-34 {
  color: #009bf2;
  font-weight: 700;
  letter-spacing: -0.03px;
}

.SP .text-wrapper-35 {
  color: #4d4d4d;
  letter-spacing: 0.08px;
}

.SP .text-wrapper-36 {
  color: #4d4d4d;
  letter-spacing: -0.59px;
}

.SP .text-wrapper-37 {
  color: #009bf2;
  font-weight: 700;
  letter-spacing: 0.08px;
}

.SP .text-wrapper-38 {
  color: #009bf2;
  font-weight: 700;
  letter-spacing: 0.1px;
}

.SP .text-wrapper-39 {
  color: #009bf2;
  font-weight: 700;
  letter-spacing: 0.15px;
}

.SP .text-wrapper-40 {
  color: #4d4d4d;
  letter-spacing: 0.15px;
}

.SP .frame-12 {
  height: 108px;
  object-fit: cover;
  position: relative;
  width: 375px;
}

.SP .div-4 {
  align-self: stretch;
  color: var(--mfa-1f9);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  position: relative;
  text-align: center;
}

.SP .text-wrapper-41 {
  letter-spacing: 0.14px;
}

.SP .text-wrapper-42 {
  letter-spacing: -0.85px;
}

.SP .text-wrapper-43 {
  letter-spacing: 0.18px;
}

.SP .text-wrapper-44 {
  letter-spacing: 0.04px;
}

.SP .text-wrapper-45 {
  letter-spacing: 0.09px;
}

.SP .text-wrapper-46 {
  letter-spacing: 0.16px;
}

.SP .text-wrapper-47 {
  letter-spacing: 0.07px;
}

.SP .text-wrapper-48 {
  letter-spacing: -0.23px;
}

.SP .text-wrapper-49 {
  letter-spacing: -0.14px;
}

.SP .text-wrapper-50 {
  letter-spacing: -0.07px;
}

.SP .text-wrapper-51 {
  letter-spacing: -0.16px;
}

.SP .text-wrapper-52 {
  letter-spacing: -0.09px;
}

.SP .text-wrapper-53 {
  letter-spacing: -0.04px;
}

.SP .text-wrapper-54 {
  letter-spacing: -0.02px;
}

.SP .text-wrapper-55 {
  letter-spacing: -0.49px;
}

.SP .img-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8.41px;
  height: 55px;
  left: 180px;
  position: absolute;
  top: 600px;
  width: 16px;
}

.SP .ellipse-wrapper {
  align-self: stretch;
  height: 17px;
  position: relative;
  width: 100%;
}

.SP .ellipse {
  background: linear-gradient(180deg, rgba(0, 155.4, 242.67, 0.4) 0%, rgba(49.82, 255, 193.45, 0.4) 100%);
  border-radius: 8.5px;
  height: 17px;
  position: relative;
  top: 1px;
  width: 17px;
}

.SP .frame-13 {
  align-self: stretch;
  height: 13px;
  position: relative;
  width: 100%;
}

.SP .ellipse-2 {
  background: linear-gradient(180deg, rgba(0, 155.4, 242.67, 0.4) 0%, rgba(49.82, 255, 193.45, 0.4) 100%);
  border-radius: 6.5px;
  height: 13px;
  left: 2px;
  position: relative;
  top: 1px;
  width: 13px;
}

.SP .frame-14 {
  align-self: stretch;
  height: 8px;
  position: relative;
  width: 100%;
}

.SP .ellipse-3 {
  background: linear-gradient(180deg, rgba(0, 155.4, 242.67, 0.4) 0%, rgba(49.82, 255, 193.45, 0.4) 100%);
  border-radius: 4px;
  height: 8px;
  left: 4px;
  position: relative;
  width: 8px;
}

.SP .cv {
  height: 535px;
  left: 0;
  position: absolute;
  top: 4191px;
  width: 375px;
}

.SP .overlap-3 {
  height: 535px;
  position: relative;
}

.SP .back-img {
  background-color: #ffffff63;
  gap: var(--1-32);
  height: 483px;
  left: 0;
  position: absolute;
  top: 52px;
  width: 375px;
}

.SP .btn {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  left: 22px;
  position: absolute;
  top: 375px;
  width: 335px;
}

.SP .micro-copy {
  height: 26px;
  position: relative;
  width: 256.88px;
}

.SP .group-7 {
  height: 26px;
  position: relative;
  width: 263px;
}

.SP .text-wrapper-56 {
  color: var(--MA-ah-6n);
  font-family: var(--SP-bold-text-s-16-160-4-bold-font-family);
  font-size: var(--SP-bold-text-s-16-160-4-bold-font-size);
  font-style: var(--SP-bold-text-s-16-160-4-bold-font-style);
  font-weight: var(--SP-bold-text-s-16-160-4-bold-font-weight);
  left: 25px;
  letter-spacing: var(--SP-bold-text-s-16-160-4-bold-letter-spacing);
  line-height: var(--SP-bold-text-s-16-160-4-bold-line-height);
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.SP .text-wrapper-57 {
  color: var(--MA-ah-6n);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 14px;
  font-weight: 900;
  left: 0;
  letter-spacing: 0.56px;
  line-height: 22.4px;
  position: absolute;
  text-align: center;
  top: 3px;
  white-space: nowrap;
}

.SP .text-wrapper-58 {
  color: var(--MA-ah-6n);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 14px;
  font-weight: 900;
  left: 243px;
  letter-spacing: 0.56px;
  line-height: 22.4px;
  position: absolute;
  text-align: center;
  top: 3px;
  transform: rotate(180deg);
  white-space: nowrap;
}

.SP .btn-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 34px;
  position: relative;
  width: 100%;
}

.SP .frame-15 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.SP .getitongoogleplay {
  height: 50px;
  object-fit: cover;
  position: relative;
  width: 168px;
}

.SP .download-on-the-app {
  height: 52px;
  position: relative;
  width: 143px;
}

.SP .frame-16 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--1-16);
  height: 177px;
  left: 136px;
  position: absolute;
  top: 148px;
  width: 219px;
}

.SP .title-5 {
  height: 56px;
  margin-right: -2px;
  position: relative;
  width: 219px;
}

.SP .overlap-group-3 {
  height: 56px;
  position: relative;
  width: 215px;
}

.SP .div-5 {
  color: var(--MA-ah-6n);
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 18px;
  font-weight: 900;
  left: 0;
  line-height: 27px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 196px;
}

.SP .text-wrapper-59 {
  letter-spacing: 0.26px;
}

.SP .text-wrapper-60 {
  letter-spacing: -0.39px;
}

.SP .text-wrapper-61 {
  letter-spacing: -0.36px;
}

.SP .text-wrapper-62 {
  letter-spacing: 0.13px;
}

.SP .text-wrapper-63 {
  color: var(--MA-ah-6n);
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 18px;
  font-weight: 900;
  left: 194px;
  letter-spacing: 1.44px;
  line-height: 27px;
  position: absolute;
  text-align: center;
  top: 27px;
  transform: rotate(14.87deg);
  white-space: nowrap;
  width: 18px;
}

.SP .lobo {
  flex: 0 0 auto;
  position: relative;
  width: 219px;
}

.SP .img-5 {
  height: 175px;
  left: 22px;
  position: absolute;
  top: 178px;
  width: 144px;
}

.SP .overlap-4 {
  height: 175px;
  position: relative;
}

.SP .img-6 {
  height: 147px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 88px;
}

.SP .img-7 {
  height: 145px;
  left: 38px;
  object-fit: cover;
  position: absolute;
  top: 30px;
  width: 106px;
}

.SP .title-6 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 999px;
  display: flex;
  gap: 8px;
  justify-content: center;
  left: 20px;
  padding: var(--1-16) 0px var(--1-16) 0px;
  position: absolute;
  top: 0;
  width: 335px;
}

.SP .rectangle-8 {
  background-color: #f3ffad;
  height: 8px;
  left: 131px;
  position: absolute;
  top: 41px;
  width: 132px;
}

.SP .div-6 {
  color: transparent;
  flex: 1;
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 22px;
  font-weight: 900;
  line-height: 39.6px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.SP .text-wrapper-64 {
  color: #4d4d4d;
  letter-spacing: 0;
}

.SP .text-wrapper-65 {
  color: #009bf2;
  font-size: 23px;
  letter-spacing: 0;
  line-height: 41.4px;
}

.SP .text-wrapper-66 {
  color: #009bf2;
  font-size: 23px;
  letter-spacing: -1.16px;
  line-height: 41.4px;
}

.SP .text-wrapper-67 {
  color: #4d4d4d;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 32.4px;
}

.SP .image-2 {
  height: 1200px;
  left: 0;
  position: absolute;
  top: 4726px;
  width: 375px;
}

.SP .view-4 {
  align-items: flex-start;
  background-color: var(--p-jrz-gm);
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 6470px;
  width: 375px;
}

.SP .frame-17 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-32);
  padding: var(--1-64) 0px var(--1-64) 0px;
  position: relative;
  width: 100%;
}

.SP .text-wrapper-68 {
  color: var(--MA-ah-6n);
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 43.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.SP .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-16);
  position: relative;
}

.SP .element-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.SP .frame-19 {
  align-items: center;
  background-color: var(--PH-wj-AW);
  border-radius: 20px 20px 0px 0px;
  box-shadow: var(--elevation-2);
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  padding: 16px;
  position: relative;
  width: 335px;
}

.SP .group-8 {
  height: 36px;
  position: relative;
  width: 38px;
}

.SP .overlap-group-4 {
  background-color: var(--MA-ah-6n);
  border-radius: 18px;
  height: 36px;
  position: relative;
  width: 36px;
}

.SP .text-wrapper-69 {
  color: var(--PH-wj-AW);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 11px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 9px;
  white-space: nowrap;
}

.SP .text-wrapper-70 {
  color: var(--MA-ah-6n);
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25.6px;
  position: relative;
}

.SP .line-wrapper {
  height: 16px;
  position: relative;
  transform: rotate(90deg);
  width: 1px;
}

.SP .line {
  height: 2px;
  left: -9px;
  position: absolute;
  top: 7px;
  transform: rotate(-90deg);
  width: 16px;
}

.SP .frame-20 {
  align-items: center;
  background-color: var(--PH-wj-AW);
  border-radius: 0px 0px 20px 20px;
  box-shadow: var(--elevation-2);
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  padding: 16px;
  position: relative;
  width: 335px;
}

.SP .text-wrapper-71 {
  color: var(--PH-wj-AW);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 12px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.SP .text-wrapper-72 {
  color: var(--mfa-1f9);
  flex: 1;
  font-family: var(--SP-medium-text-xs-14-160-8-medium-font-family);
  font-size: var(--SP-medium-text-xs-14-160-8-medium-font-size);
  font-style: var(--SP-medium-text-xs-14-160-8-medium-font-style);
  font-weight: var(--SP-medium-text-xs-14-160-8-medium-font-weight);
  letter-spacing: var(--SP-medium-text-xs-14-160-8-medium-letter-spacing);
  line-height: var(--SP-medium-text-xs-14-160-8-medium-line-height);
  margin-top: -1px;
  position: relative;
}

.SP .text-wrapper-73 {
  color: var(--MA-ah-6n);
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25.6px;
  margin-top: -1px;
  position: relative;
}

.SP .youtube {
  align-items: center;
  background-color: var(--PH-wj-AW);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-24);
  padding: var(--1-56) 0px var(--1-56) 0px;
  position: relative;
  width: 375px;
}

.SP .frame-21 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.SP .movie {
  height: 179px;
  position: relative;
  width: 255px;
}

.SP .frame-22 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: var(--1-8);
  justify-content: center;
  position: relative;
  width: 100%;
}

.SP .yt-logo-rgb-light {
  height: 31px;
  object-fit: cover;
  position: relative;
  width: 140px;
}

.SP .frame-23 {
  align-items: center;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 8px;
  position: relative;
}

.SP .text-wrapper-74 {
  color: var(--MA-ah-6n);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.96px;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.SP .frame-24 {
  align-items: center;
  background: linear-gradient(180deg, rgba(0, 155.4, 242.67, 0.3) 0%, rgba(49.82, 255, 193.45, 0.3) 100%);
  border-radius: 999px;
  box-shadow: var(--elevation-2);
  display: flex;
  gap: 8px;
  height: 54px;
  justify-content: center;
  padding: var(--1-16) 24px var(--1-16) 30px;
  position: relative;
  width: 261px;
}

.SP .ei-sc-youtube {
  height: 50px;
  margin-bottom: -14px;
  margin-top: -14px;
  position: relative;
  width: 50px;
}

.SP .text-wrapper-75 {
  color: var(--mfa-1f9);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.12px;
  line-height: 21px;
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.SP .footer {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff5e;
  background-image: url(../../../static/img/footer.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: var(--1-48);
  height: 1026px;
  justify-content: center;
  padding: var(--1-56) 20px var(--1-56) 20px;
  position: relative;
  width: 100%;
}

.SP .frame-25 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-48);
  position: relative;
  width: 100%;
}

.SP .g {
  height: 34px;
  position: relative;
  width: 160px;
}

.SP .frame-26 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-16);
  position: relative;
  width: 100%;
}

.SP .text-wrapper-76 {
  align-self: stretch;
  color: var(--u-xe-hpg);
  font-family: var(--SP-medium-text-xs-14-160-8-medium-font-family);
  font-size: var(--SP-medium-text-xs-14-160-8-medium-font-size);
  font-style: var(--SP-medium-text-xs-14-160-8-medium-font-style);
  font-weight: var(--SP-medium-text-xs-14-160-8-medium-font-weight);
  letter-spacing: var(--SP-medium-text-xs-14-160-8-medium-letter-spacing);
  line-height: var(--SP-medium-text-xs-14-160-8-medium-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.SP .text-wrapper-77 {
  align-self: stretch;
  color: var(--u-xe-hpg);
  font-family: var(--SP-medium-text-xs-14-160-8-medium-font-family);
  font-size: var(--SP-medium-text-xs-14-160-8-medium-font-size);
  font-style: var(--SP-medium-text-xs-14-160-8-medium-font-style);
  font-weight: var(--SP-medium-text-xs-14-160-8-medium-font-weight);
  letter-spacing: var(--SP-medium-text-xs-14-160-8-medium-letter-spacing);
  line-height: var(--SP-medium-text-xs-14-160-8-medium-line-height);
  position: relative;
  text-align: center;
}

.SP .frame-27 {
  align-items: center;
  background: linear-gradient(180deg, rgba(0, 155.4, 242.67, 0.3) 0%, rgba(49.82, 255, 193.45, 0.3) 100%);
  border-radius: 999px;
  box-shadow: var(--elevation-2);
  display: inline-flex;
  gap: 8px;
  height: 64px;
  justify-content: center;
  padding: var(--1-16) 16px var(--1-16) 22px;
  position: relative;
}

.SP .ei-sc-youtube-2 {
  height: 50px;
  margin-bottom: -9px;
  margin-top: -9px;
  position: relative;
  width: 50px;
}

.SP .you-tube {
  color: transparent;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.04px;
  line-height: 19.5px;
  margin-bottom: -4px;
  margin-top: -6px;
  position: relative;
  width: fit-content;
}

.SP .text-wrapper-78 {
  color: #ffffff;
  letter-spacing: 0.14px;
}

.SP .text-wrapper-79 {
  color: #4d4d4d;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 21px;
}

.SP .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.SP .text-2 {
  align-self: stretch;
  color: transparent;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 20.8px;
  position: relative;
}

.SP .text-wrapper-80 {
  color: #4d4d4d;
  font-weight: 500;
  letter-spacing: 0.07px;
}

.SP .text-wrapper-81 {
  color: #004871;
  font-weight: 700;
  letter-spacing: 0.07px;
}

.SP .text-wrapper-82 {
  color: #004871;
  font-weight: 700;
  letter-spacing: -0.32px;
}

.SP .frame-28 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 26px;
  position: relative;
  width: 335px;
}

.SP .frame-29 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.SP .div-7 {
  align-self: stretch;
  color: var(--MA-ah-6n);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.SP .text-wrapper-83 {
  letter-spacing: 0.22px;
}

.SP .text-wrapper-84 {
  letter-spacing: -0.12px;
}

.SP .text-wrapper-85 {
  letter-spacing: -0.24px;
}

.SP .frame-30 {
  height: 46px;
  object-fit: cover;
  position: relative;
  width: 335px;
}

.SP .frame-31 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 335px;
}

.SP .frame-32 {
  height: 46px;
  object-fit: cover;
  position: relative;
  width: 163px;
}

.SP .frame-33 {
  height: 46px;
  object-fit: cover;
  position: relative;
  width: 162px;
}

.SP .text-btn {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 24px 20px;
  position: relative;
  width: 100%;
}

.SP .text-wrapper-86 {
  color: var(--MA-ah-6n);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.04px;
  line-height: 20.8px;
  margin-left: -20.5px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  width: 168px;
}

.SP .text-wrapper-87 {
  color: var(--MA-ah-6n);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.04px;
  line-height: 20.8px;
  margin-right: -20.5px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  width: 168px;
}

.SP .text-wrapper-88 {
  color: var(--u-xe-hpg);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: 19.2px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.SP .view-5 {
  align-items: center;
  background-image: url(../../../static/img/image.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: var(--1-32);
  left: 0;
  padding: 40px 0px var(--1-64) 0px;
  position: absolute;
  top: 681px;
  width: 375px;
}

.SP .div-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.SP .div-9 {
  color: transparent;
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 22px;
  font-weight: 900;
  line-height: 26.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.SP .text-wrapper-89 {
  color: #4d4d4d;
  letter-spacing: 0.39px;
}

.SP .text-wrapper-90 {
  color: #009bf2;
  font-size: 27px;
  letter-spacing: 0.15px;
  line-height: 32.4px;
}

.SP .frame-34 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.SP .element-5 {
  align-items: center;
  background-color: var(--PH-wj-AW);
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-8);
  justify-content: center;
  padding: 8px 24px;
  position: relative;
  width: 335px;
}

.SP .frame-35 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--1-8);
  position: relative;
}

.SP .img-wrapper {
  background: linear-gradient(180deg, rgba(0, 155.4, 242.67, 0.4) 0%, rgba(49.82, 255, 193.45, 0.4) 100%);
  border-radius: 20px;
  height: 40px;
  position: relative;
  width: 40px;
}

.SP .group-9 {
  height: 21px;
  left: 6px;
  position: absolute;
  top: 9px;
  width: 27px;
}

.SP .text-wrapper-91 {
  color: var(--mfa-1f9);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.72px;
  line-height: 27px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.SP .element-6 {
  align-items: center;
  background-color: var(--PH-wj-AW);
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: var(--1-16) var(--1-24) var(--1-16) var(--1-24);
  position: relative;
  width: 335px;
}

.SP .frame-36 {
  height: 40px;
  position: relative;
  width: 40px;
}

.SP .element-wrapper {
  background: linear-gradient(180deg, rgba(0, 155.4, 242.67, 0.4) 0%, rgba(49.82, 255, 193.45, 0.4) 100%);
  border-radius: 18.5px;
  height: 37px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 37px;
}

.SP .element-7 {
  height: 26px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 26px;
}

.SP .element-8 {
  align-items: center;
  background-color: var(--PH-wj-AW);
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 8px 24px;
  position: relative;
  width: 335px;
}

.SP .frame-37 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.SP .frame-38 {
  height: 28px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 28px;
}

.SP .frame-39 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  left: 164px;
  position: absolute;
  top: 136px;
  width: 8px;
}

.SP .frame-40 {
  align-self: stretch;
  background: linear-gradient(180deg, rgba(0, 155.4, 242.67, 0.4) 0%, rgba(49.82, 255, 193.45, 0.4) 100%);
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 100%;
}

.SP .frame-41 {
  align-self: stretch;
  height: 6px;
  position: relative;
  width: 100%;
}

.SP .ellipse-4 {
  background: linear-gradient(180deg, rgba(0, 155.4, 242.67, 0.4) 0%, rgba(49.82, 255, 193.45, 0.4) 100%);
  border-radius: 3px;
  height: 6px;
  left: 1px;
  position: relative;
  width: 6px;
}

.SP .frame-42 {
  align-self: stretch;
  height: 4px;
  position: relative;
  width: 100%;
}

.SP .ellipse-5 {
  background: linear-gradient(180deg, rgba(0, 155.4, 242.67, 0.4) 0%, rgba(49.82, 255, 193.45, 0.4) 100%);
  border-radius: 2px;
  height: 4px;
  left: 2px;
  position: relative;
  width: 4px;
}

.SP .fv {
  background-image: url(../../../static/img/back-img.png);
  background-size: 100% 100%;
  height: 681px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.SP .btn-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--1-8);
  left: 20px;
  position: absolute;
  top: 568px;
  width: 335px;
}

.SP .text-wrapper-92 {
  color: var(--u-xe-hpg);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.72px;
  line-height: 28.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.SP .text-wrapper-93 {
  color: var(--u-xe-hpg);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.72px;
  line-height: 28.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  transform: rotate(180deg);
  white-space: nowrap;
  width: fit-content;
}

.SP .btn-4 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.SP .overlap-5 {
  height: 260px;
  left: 20px;
  position: absolute;
  top: 288px;
  width: 335px;
}

.SP .element-merit {
  height: 118px;
  left: 0;
  position: absolute;
  top: 142px;
  width: 335px;
}

.SP .overlap-6 {
  height: 118px;
  position: relative;
}

.SP .element-9 {
  background-color: var(--PH-wj-AW);
  border: 2px solid;
  border-color: var(--MA-ah-6n);
  border-radius: 59px;
  box-shadow: 0px 3.77px 3.77px #00000040;
  height: 118px;
  left: 217px;
  position: absolute;
  top: 0;
  width: 118px;
}

.SP .image-3 {
  height: 15px;
  left: 11px;
  position: absolute;
  top: 36px;
  width: 94px;
}

.SP .group-10 {
  height: 17px;
  left: 21px;
  position: absolute;
  top: 63px;
  width: 73px;
}

.SP .group-wrapper {
  background-color: var(--PH-wj-AW);
  border: 2px solid;
  border-color: var(--MA-ah-6n);
  border-radius: 59px;
  box-shadow: 0px 3.77px 3.77px #00000040;
  height: 118px;
  left: 110px;
  position: absolute;
  top: 0;
  width: 118px;
}

.SP .group-11 {
  height: 76px;
  left: 18px;
  position: absolute;
  top: 20px;
  width: 78px;
}

.SP .element-10 {
  background-color: var(--PH-wj-AW);
  border: 2px solid;
  border-color: var(--MA-ah-6n);
  border-radius: 59px;
  box-shadow: 0px 3.77px 3.77px #00000040;
  height: 118px;
  left: 0;
  position: absolute;
  top: 0;
  width: 118px;
}

.SP .image-4 {
  height: 11px;
  left: 34px;
  position: absolute;
  top: 23px;
  width: 46px;
}

.SP .image-5 {
  height: 9px;
  left: 14px;
  position: absolute;
  top: 82px;
  width: 84px;
}

.SP .overlap-group-5 {
  height: 28px;
  left: 9px;
  position: absolute;
  top: 43px;
  width: 101px;
}

.SP .image-6 {
  height: 11px;
  left: 89px;
  position: absolute;
  top: 18px;
  width: 12px;
}

.SP .element-11 {
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 93px;
}

.SP .element-12 {
  height: 157px;
  left: 176px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100px;
}

.SP .catchcopy {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  left: 24px;
  position: absolute;
  top: 0;
  width: 327px;
}

.SP .logo-2 {
  height: 64px;
  position: relative;
  width: 185px;
}

.SP .overlap-group-6 {
  background-image: url(../../../static/img/logo-1.png);
  background-size: 100% 100%;
  height: 64px;
  position: relative;
  width: 183px;
}

.SP .text-wrapper-94 {
  color: var(--u-xe-hpg);
  font-family: "Tsunagi Gothic Black-Black", Helvetica;
  font-size: 11px;
  font-weight: 900;
  left: 80px;
  letter-spacing: 0.88px;
  line-height: 18.7px;
  position: absolute;
  text-align: center;
  text-shadow: 0px 0px 3px #ffffff;
  top: 45px;
  white-space: nowrap;
}

.SP .catchcopy-2 {
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.SP .element-13 {
  height: 544px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 5926px;
  width: 375px;
}
